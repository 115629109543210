import {
  GetTimeSlotsRequest,
  Status,
  TimeSlot,
} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {getTimeSlots as getTimeSlotsService} from '@wix/ambassador-table-reservations-v1-time-slot/http'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {utcToZonedTime, zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'
import addDays from 'date-fns/addDays'
import isSameDay from 'date-fns/isSameDay'
import isPast from 'date-fns/isPast'

import {ITimeSlotsByDays} from '../components/Reservations/constants'

const TARGET_TIME_SLOTS_AMOUNT = 15
const TARGET_TIME_SLOTS_AMOUNT_NEXT_DAYS = 5

const getTimeSlots = async (
  flowAPI: ControllerFlowAPI,
  params: GetTimeSlotsRequest,
  tz?: string | null,
  statuses: Status[] = Object.values(Status),
): Promise<ITimeSlotsByDays> => {
  if (params.date && tz) {
    params.date = zonedTimeToUtc(params.date, tz)
  }

  let timeSlots = await getTimeSlotsRequest(flowAPI, params)

  if (isSomeSlotAvailable(timeSlots)) {
    timeSlots = getAppropriateTimeSlots(timeSlots, params.date, TARGET_TIME_SLOTS_AMOUNT, statuses)

    return {
      [params.date.toDateString()]: timeSlots.map((t) => ({
        ...t,
        startDate: utcToZonedTime(t.startDate!, tz),
      })),
    }
  } else {
    const days = getDaysAroundDate(params.date)

    const responses = (
      await Promise.all(
        days.map(async (date) => {
          const allSlots = await getTimeSlotsRequest(flowAPI, {
            ...params,
            date,
          })

          return getAppropriateTimeSlots(
            allSlots,
            date,
            TARGET_TIME_SLOTS_AMOUNT_NEXT_DAYS,
            statuses,
          )
        }),
      )
    ).map((slots) =>
      slots.map((t) => ({
        ...t,
        startDate: utcToZonedTime(t.startDate!, tz),
      })),
    )

    const result = {}

    days.forEach((day, index) => {
      if (isSomeSlotAvailable(responses[index])) {
        result[day.toDateString()] = responses[index]
      }
    })

    return result
  }
}

const getDaysAroundDate = (date: Date) => {
  const DAYS_COUNT = 5
  const MAX_DAYS_BEFORE = 2
  const days: Date[] = []

  let i = 0

  while (days.length < DAYS_COUNT) {
    const day = addDays(addDays(date, -MAX_DAYS_BEFORE), i)

    // TODO: respect schedule
    if (!isSameDay(date, day) && !isPast(day)) {
      days.push(day)
    }

    i++
  }

  return days
}

const isSomeSlotAvailable = (timeSlots: TimeSlot[]) =>
  timeSlots.some((slot) => slot.status === Status.AVAILABLE)

export const getAppropriateTimeSlots = (
  timeSlots: TimeSlot[],
  targetDate: Date,
  targetSlotsCount: number = 15,
  statuses: Status[] = Object.values(Status),
) => {
  const targetTime = targetDate.getTime()
  const sortedTimeSlots = [...timeSlots]
    .filter((slot) => statuses.includes(slot.status!))
    .sort((a, b) => a.startDate!.getTime() - b.startDate!.getTime())

  let closestIndex = 0
  let minDiff = Math.abs(sortedTimeSlots[0].startDate!.getTime() - targetTime)

  for (let i = 1; i < sortedTimeSlots.length; i++) {
    const diff = Math.abs(sortedTimeSlots[i].startDate!.getTime() - targetTime)
    if (diff < minDiff) {
      closestIndex = i
      minDiff = diff
    }
  }

  let start = Math.max(0, closestIndex - Math.floor((targetSlotsCount - 1) / 2))
  const end = Math.min(sortedTimeSlots.length, start + targetSlotsCount)

  start = Math.max(0, end - targetSlotsCount)

  return sortedTimeSlots.slice(start, end)
}

const getTimeSlotsRequest = async (
  flowAPI: ControllerFlowAPI,
  params: GetTimeSlotsRequest,
): Promise<TimeSlot[]> => {
  const req = getTimeSlotsService({
    slotsBefore: 50,
    slotsAfter: 50,
    ...params,
  })

  const res = await flowAPI.httpClient.request(req)

  return res.data.timeSlots ?? []
}

const EXTENDED_TYPE_SLOTS_COUNT = 5

export const timeSlotsService = {
  getTimeSlots,
  EXTENDED_TYPE_SLOTS_COUNT,
}

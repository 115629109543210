import {dataHooks} from '@wix/data-hooks'
import {Status} from '@wix/ambassador-table-reservations-v1-time-slot/types'

import {ShowSlots} from '../Settings/Tabs/Display/settingsParams'

export const reservationsDataHooks = dataHooks<{
  root: {}
}>('reservations')

export enum ReservationsPageType {
  DEFAULT,
  EXTENDED,
  EMPTY,
}

export const SlotsSettingToStatus: Record<ShowSlots, Status[]> = {
  [ShowSlots.All]: [Status.AVAILABLE, Status.NON_WORKING_HOURS, Status.UNAVAILABLE],
  [ShowSlots.Available]: [Status.AVAILABLE],
}

export const SlotsSettingToRequestedSlotsQuantity: Record<ShowSlots, number> = {
  [ShowSlots.All]: 7,
  [ShowSlots.Available]: 50,
}

import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {SectionNotification} from 'wix-ui-tpa/cssVars'

import {getMediumDate, getShortTime} from '../../../../utils/dateString'

import {classes, st} from './NotAvailableTimeSlotNotification.st.css'

interface Props {
  date: Date
  partySize: number
  regionalSettings?: string
}

export const NotAvailableTimeSlotNotification: React.FC<Props> = ({
  date,
  partySize,
  regionalSettings,
}) => {
  const {t} = useTranslation()

  return (
    <div className={classes.container}>
      <SectionNotification className={st(classes.root)}>
        <SectionNotification.Text>
          {t('uou-reservations.reservations.time-slot-unavailable', {
            number: partySize,
            date: getMediumDate(date, regionalSettings),
            time: getShortTime(date, regionalSettings),
          })}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  )
}

import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export enum ShowSlots {
  All = 'all',
  Available = 'available',
}

export type DisplaySettingsParams = {
  showTitle: SettingsParamType.Boolean
  showDescription: SettingsParamType.Boolean
  showSlots: SettingsParamType.String
}

export const DEFAULT_DISPLAY_SETTINGS_PARAMS = {
  showTitle: true,
  showDescription: true,
  showSlots: ShowSlots.Available,
}

export const displaySettingsParams = createSettingsParams<DisplaySettingsParams>({
  showTitle: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showTitle,
  },
  showDescription: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showDescription,
  },
  showSlots: {
    type: SettingsParamType.String,
    getDefaultValue: () => DEFAULT_DISPLAY_SETTINGS_PARAMS.showSlots,
  },
})

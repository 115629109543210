import {StyleParamType, wixColorParam, createStylesParams} from '@wix/tpa-settings'

export type NotificationStylesParams = {
  notificationFillColor: StyleParamType.Color
  notificationTextColor: StyleParamType.Color
}

export const notificationStylesParams = createStylesParams<NotificationStylesParams>({
  notificationFillColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.05),
  },
  notificationTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 1),
  },
})
